import React from 'react'

const CompFBan = (props) => (
    <section id="banner" className="style2 bg-image-compf">
        <div className="inner">
            <header className="major">
                <h1>Competency F</h1>
            </header>
            <div className="content">
                <p>Conduct informatics analysis and visualization applied to different real-world fields, such as health science and privacy</p>
            </div>
        </div>
    </section>
)

export default CompFBan
